<template>
  <div class="frontend-page-wrap">
    <div class="bg-article"></div>
    <div class="line_wrap line_white">
       <div class="line_item"></div>
       <div class="line_item"></div>
       <div class="line_item"></div>
       <div class="line_item"></div>
       <div class="line_item"></div>
    </div>
    <AppHeader></AppHeader>
    <section class="content">
      <div class="container-fluid py-3">
        <el-breadcrumb separator="/" v-if="route.meta.tag">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ route.meta.tag }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container-fluid"><router-view></router-view></div>
    </section>
  </div>
</template>

<script>
import AppHeader from '@/components/header.vue'
export default {
  components: {
    AppHeader,
  },

  data() {
    return {
      route: this.$route
    }
  },

  watch: {
    $route(to, from) {
      this.route = to
    }
  },

  computed: {
  },

  mounted() {
  },

  methods: {

  }
}
</script>
