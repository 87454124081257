<template>
  <div class="page-recharge">
    <div class="row justify-content-center align-items-center my-auto">
      <div class="col-md-7 text-shadow">
        <div class="card df-card shadow-lg shadow-lg py-4" style="background-color: rgba(40, 42, 62, .85);">
          <div class="card-body">
            <h3 class="card-title text-center mt-2 mb-5 text-white">修改密码</h3>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px">
              <el-form-item label="原密码" prop="old_password">
                <el-input v-model="form.old_password" autocomplete="off" placeholder="请填写原密码"></el-input>
              </el-form-item>
              <el-form-item label="新密码" prop="new_password">
                <el-input v-model="form.new_password" autocomplete="off" placeholder="请填写新密码"></el-input>
              </el-form-item>
              <el-form-item class="mt-5">
                <el-button type="primary" @click="submitForm('form')">提交</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        old_password: '',
        new_password: ''
      },

      rules: {
        old_password: [
          { required: true, message: '请输入原密码', trigger: 'change' }
        ],
        new_password: [
          { required: true, message: '请输入新密码', trigger: 'change' }
        ],
      }
    }
  },

  mounted() {
  },

  methods: {
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$http.put('/change_password', this.form).then(res => {
            if (!res.data) return this.$message({message: res.message, type: 'error'});
            this.$message({message: res.message, type: 'success'})
            localStorage.setItem('Authorization', res.data.token)
            this.$router.push('/');
          })
        }
      });
    },
  },
}
</script>


<style lang="scss">
.paycode-wrap {
  .el-radio {
    color: #aaa;
  }

  .el-radio.is-bordered {
    // border-color: #7384ad;
  }

  .el-radio__inner {
    border-color: #8b96b7;
  }
}
</style>
