<template>
  <div class="frontend-page-wrap">
    <AppHeader></AppHeader>
    <div class="bg-article"></div>
    <div class="line_wrap line_white">
       <div class="line_item"></div>
       <div class="line_item"></div>
       <div class="line_item"></div>
       <div class="line_item"></div>
       <div class="line_item"></div>
    </div>
    <section class="content header-data">
      <div class="container-fluid mt-4 mb-5">
        <div class="row">
          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card">
              <div class="card-header"><h5 class="mb-0">余额</h5></div>
              <div class="card-body color-balance">
                <div class="title">{{ user ? user.balance : '...' }}</div>
                <div class="info"><i class="iconfont">&#xe61c;</i></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center"><h5 class="mb-0">提现</h5><router-link class="text-decoration-none text-white" :to="{name: 'frontend_transactions', params: {trans_type: 'exchange'}}"><i class="el-icon-arrow-right"></i></router-link></div>
              <div class="card-body color-exchange">
                <div class="title">{{ user ? user.exchange_amount : '...' }}</div>
                <div class="info"><i class="iconfont">&#xe63d;</i></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center"><h5 class="mb-0">罚款</h5><router-link class="text-decoration-none text-white" :to="{name: 'frontend_transactions', params: {trans_type: 'punish'}}"><i class="el-icon-arrow-right"></i></router-link></div>
              <div class="card-body color-punish">
                <div class="title">{{ user ? user.punish_amount : '...' }}</div>
                <div class="info"><i class="iconfont">&#xe60b;</i></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center"><h5 class="mb-0">奖励</h5><router-link class="text-decoration-none text-white" :to="{name: 'frontend_transactions', params: {trans_type: 'reward'}}"><i class="el-icon-arrow-right"></i></router-link></div>
              <div class="card-body color-reward">
                <div class="title">{{ user ? user.reward_amount : '...' }}</div>
                <div class="info"><i class="iconfont">&#xe6d1;</i></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center">
                <h5>链上数据</h5>
                <router-link class="btn btn-link text-decoration-none text-white" :to="{name: 'frontend_transactions'}">更多</router-link>
              </div>
              <div class="card-body black-table"
                v-loading="loading"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.5)"
                >
                <el-table
                  :data="transactions"
                  style="width: 100%;">
                  <el-table-column prop="id" label="ID" min-width="50"></el-table-column>
                  <el-table-column prop="from_name" label="转出" min-width="150">
                    <template slot-scope="scope">
                      <div>转出名称：{{ scope.row.from_name }}</div>
                      <div v-if="scope.row.from_user.telephone">转出电话：{{ scope.row.from_user.telephone }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="to_name" label="转入" min-width="200">
                    <template slot-scope="scope">
                      <div>转入名称：{{ scope.row.to_name }}</div>
                      <div v-if="scope.row.to_user.telephone">转入电话：{{ scope.row.to_user.telephone }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value" label="金额">
                    <template slot-scope="scope">{{ scope.row.value / 100 }}</template>
                  </el-table-column>
                  <el-table-column prop="comment" label="备注" min-width="140"></el-table-column>
                  <el-table-column prop="datetime" label="转账时间" min-width="160"></el-table-column>
                  <el-table-column prop="" label="操作">
                    <template slot-scope="scope">
                      <el-link target="_blank" :href="'https://confluxscan.io/transaction/' + scope.row.hash" type="success">点击查看</el-link>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
<!--         <div class="page-dashboard d-flex flex-column vh-100" style="margin-top: -130px;">
          <div class="row justify-content-center align-items-center my-auto">
            <div class="col-md-12 text-center text-shadow mt-n5">
              <div class="gdt-wrap mb-5">
                <div class="title">GDT 总额：</div>
                <div class="title-mb">GDT 总额</div>
                <countTo v-if="user" :startVal='0' :endVal='user.balance' :duration='800'></countTo>
                <span v-else>0</span>
              </div>
              <div class="btns d-flex justify-content-center align-items-center">
                <router-link to="/recharge" class="btn btn-link">充值</router-link>
                <span class="mx-4"></span>
                <a class="btn btn-link btn-right" @click="onExchange">提现</a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </section>

  </div>
</template>

<script>
import AppHeader from '@/components/header.vue'
// import countTo from 'vue-count-to';

export default {
  components: {
    // countTo,
    AppHeader
  },

  data() {
    return {
      loading: false,
      transactions: [],
    }
  },

  computed: {
    user() {
      return this.$store.state.gdtAppConfig.current_user
    },
  },

  mounted() {
    this.$http.get('profile', null, {hmsg: true}).then(res => {
      this.$store.commit('setGdtAppConfig', res.data);
      this.getTransactions();
    })
  },

  methods: {
    getTransactions() {
      this.loading = true;

      const _data = {
        page: 1,
        limit: 8
      }

      this.$http.get('/transactions', _data).then(res => {
        this.transactions = res.data.data;
        this.loading = false;
      })
    },
  },
}
</script>
