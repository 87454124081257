<template>
  <div class="black-table">
    <div class="widget-flat">
      <div class="data-table card df-card">
        <div class="card-body"
          style="min-height: 600px;"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-background="rgba(0, 0, 0, 0.5)"
          >
          <el-table
            :data="withdraws"
            style="width: 100%;">
            <el-table-column prop="id" label="ID" min-width="50"></el-table-column>
            <el-table-column prop="from_name" label="提现用户" min-width="180">
              <template slot-scope="scope">
                <div>姓名：{{ scope.row.user.name }}</div>
                <div v-if="scope.row.user.name">电话：{{ scope.row.user.telephone }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="value" label="金额" min-width="110">
              <template slot-scope="scope">{{ scope.row.amount / 100 }}</template>
            </el-table-column>
            <el-table-column prop="updated_at" label="链上时间" min-width="180"></el-table-column>
            <el-table-column prop="status" label="状态" min-width="110"></el-table-column>
            <el-table-column prop="" label="链上记录" min-width="110">
              <template slot-scope="scope">
                <el-link target="_blank" :href="'https://confluxscan.io/transaction/' + scope.row.transaction_no" type="success">点击查看</el-link>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            background
            v-if="withdraws.length && withdraws.total_page > 1"
            class="mt-5"
            @current-change="currentChange"
            layout="prev, pager, next"
            :total="total_records">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      withdraws: [],
      current_page: 1,
      total_records: 0,
    }
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      this.loading = true;

      const _data = {
        page: this.current_page
      }

      this.$http.get('withdraws', _data).then(res => {
        this.withdraws = res.data.data;
        this.total_records = res.data.total_records;
        this.current_page = res.data.current_page;
        this.loading = false;
      })
    },

    currentChange(e) {
      this.current_page = e;
      this.loadData();
    },
  },
}
</script>
<style lang="scss">
</style>
