<template>
  <div class="black-table">
    <div class="widget-flat card mb-3">
      <div class="card-header">筛选</div>
      <div class="card-body card-header-filter">
        <div class="filter-item">
          <div class="title">用户</div>
          <el-select v-model="filters.user_id" placeholder="请选择" size="small" filterable style="max-width: 100px">
            <el-option
              v-for="item in filterUsers"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <div class="title">类型</div>
          <el-radio-group v-model="filters.type" size="small">
            <el-radio-button :label="item.code" v-for="item in types" :key="item.code">
              {{ item.name }}
            </el-radio-button>
          </el-radio-group>
        </div>

        <div class="filter-item">
          <div class="title">状态</div>
          <el-radio-group v-model="filters.status" size="small">
            <el-radio-button :label="item.code" v-for="item in status" :key="item.code">
              {{ item.name }}
            </el-radio-button>
          </el-radio-group>
        </div>

        <div class="filter-item filter-at-item">
          <div class="title">时间</div>
          <div class="filter-at-wrap">
            <el-date-picker
              v-model="filters.start_at"
              type="date"
              size="small"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
            </el-date-picker>
            <span>至</span>
            <el-date-picker
              v-model="filters.end_at"
              type="date"
              size="small"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="widget-flat">
      <div class="data-table card df-card">
        <div class="card-body"
          style="min-height: 600px;"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-background="rgba(0, 0, 0, 0.5)"
          >
          <div class="page-title-right d-flex justify-content-between mb-3">
            <div>
              <router-link :to="{name: 'orders.create'}" class="btn btn-danger btn-sm mr-2">创建惩罚预付单</router-link>
              <router-link :to="{name: 'orders.create.batch'}" class="btn btn-primary btn-sm">批量创建奖励单</router-link>
            </div>
            <div>
              <el-button type="success" @click="batchAudit" size="small">批量审核</el-button>
            </div>
          </div>
          <el-table
            :data="usres"
            ref="multipleTable"
            row-key="id"
            @selection-change="handleSelectionChange"
            style="width: 100%;">
            <el-table-column type="selection" :reserve-selection="true" :selectable="isSelection" min-width="45"></el-table-column>
            <el-table-column prop="id" label="ID" min-width="50" align="center"></el-table-column>
            <el-table-column prop="amount" label="金额" min-width="80" align="center"></el-table-column>
            <el-table-column prop="type" label="类型" min-width="100"></el-table-column>
            <!-- <el-table-column prop="user.name" label="名称" min-width="80"> -->
            <el-table-column prop="" label="名称" min-width="80">
              <template slot-scope="scope">
                <!-- <el-link :href="'https://guangda.network/admin/users/' + scope.row.user_id + '/info'" type="success">{{ scope.row.user.name }}</el-link> -->
                <router-link style="color: #fddf30;text-decoration: none;" :to="{name:'users.info', params: {id: scope.row.user_id}}" type="success">{{ scope.row.user.name }}</router-link>
              </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="admin.name" label="操作人" min-width="80"></el-table-column>
            <el-table-column prop="status" label="状态" min-width="110"></el-table-column>
            <el-table-column prop="created_at" label="创建时间" min-width="100">
              <template slot-scope="scope">
                {{ scope.row.created_at.split(' ')[0] }}<br>{{ scope.row.created_at.split(' ')[1] }}
              </template>
            </el-table-column>
            <el-table-column prop="comment" label="备注" min-width="220"></el-table-column>
            <el-table-column prop="" label="链上记录" min-width="100" align="center">
              <template slot-scope="scope">
                <el-link target="_blank" v-if="scope.row.transaction_no" :href="'https://confluxscan.io/transaction/' + scope.row.transaction_no" type="success">点击查看</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" width="80" align="center">
              <template slot-scope="scope">
                <!-- <el-button type="info" plain size="small" :to="{name: 'user_info', params: {id: scope.row.id}}">查看</el-button> -->
                <router-link :to="{name:'orders.info', params: {id: scope.row.id}}">
                  <el-button type="info" plain size="small">查看</el-button>
                </router-link>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            background
            v-if="usres.length"
            class="mt-5"
            @current-change="currentChange"
            layout="prev, pager, next"
            :total="total_records">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      usres: [],

      current_page: 1,
      total_records: 0,
      filters: {
        type: '',
        status: '',
        user_id: ''
      },

      filterUsers: [{name: '全部', id:''}],
      types: [{code: '', name: '全部'}, {code: 'punish', name: '罚款'}, {code: 'reward', name: '奖励'}],
      status: [
        {code: '', name: '全部'},
        {code: 'pending', name: '等待'},
        {code: 'approved', name: '审核'},
        {code: 'cancelled', name: '取消'},
        {code: 'transferring', name: '上链中'},
        {code: 'completed', name: '完成'}
      ],
      usresChecked: []
    }
  },

  watch: {
    filters: {
      handler(after, before) {
        this.current_page = 1;
        this.loadData();
      },
      deep: true
    },
  },

  mounted() {
    this.loadData();
    this.getUsers();
  },

  methods: {
    loadData() {
      this.loading = true;
      this.filters.page = this.current_page;

      this.$http.get('admin/orders', this.filters).then(res => {
        this.usres = res.data.data || [];
        this.total_records = res.data.total_records;
        this.current_page = res.data.current_page;
        this.loading = false;
      })
    },

    getUsers() {
      const self = this;

      this.$http.get('admin/users', {limit: 1000}).then(res => {
        if (res.data) {
          let users = res.data.data.map(e => {
            return {
              name: e.name,
              id: e.id,
            }
          });

          self.filterUsers.push(...users);
        }
      })
    },

    isSelection(row, index) {
      return row.status == 'pending' ? true : false;
    },

    handleSelectionChange(e) {
      this.usresChecked = e.map(x => x.id);
    },

    batchAudit() {
      let ids = this.usresChecked.join(',');
      if (!ids) {
        this.$swal.fire({icon: 'info', title: '请至少勾选一个列表'});
        return;
      }

      this.$http.post('admin/users/verify_code', null).then(res => {
        if (res.data) {
          this.$swal.fire({
            title: '验证码已发送',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            inputPlaceholder: '请输入验证码',
            showCancelButton: true,
            // confirmButtonText: 'Look up',
            showLoaderOnConfirm: true,
            preConfirm: (sms_code) => {
              return this.$http.put('admin/orders/batch_approve',
                {ids, sms_code}, {hmsg: true}).then(res => {
                return res;
              }).catch(error => {
                this.$swal.showValidationMessage(error.message);
              })
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          }).then((res) => {
            this.$swal.fire({title: res.value.message, icon: 'success'})
            this.loadData();
          })
        }
      }).catch(() => {this.$swal.hideLoading()})
    },

    currentChange(e) {
      this.current_page = e;
      this.loadData();
    },
  },
}
</script>
<style lang="scss">
  .mr-2 {
    margin-right: 20px;
  }

  @media (max-width: 1570px) {
    .filter-at-item {
      margin-top: 16px;
    }
  }

  .btn-no-batch {
    margin-right: 10px;
    color: #fff !important;
    border-color: #fd5631 !important;
    background-color: #fd5631 !important;

    &:hover {
      background-color: #f5795d !important;
      border-color: #f5795d !important;
    }
  }
</style>
