<template>
  <div class="black-table">
    <div class="widget-flat card mb-3">
      <div class="card-header">筛选</div>
      <div class="card-body card-header-filter">
        <div class="filter-item">
          <div class="title">用户</div>
          <el-select v-model="filters.user_id" placeholder="请选择" size="small">
            <el-option
              v-for="item in filterUsers"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </div>

        <div class="filter-item">
          <div class="title">状态</div>
          <el-radio-group v-model="filters.trans_type" size="small">
            <el-radio-button :label="item.code" v-for="item in filterTransType" :key="item.code">
              {{ item.name }}
            </el-radio-button>
          </el-radio-group>
        </div>

        <div class="filter-item">
          <div class="title">时间</div>
          <div class="filter-at-wrap">
            <el-date-picker
              v-model="filters.start_at"
              type="date"
              value-format="yyyy-MM-dd"
              size="small"
              placeholder="选择日期">
            </el-date-picker>
            <span>至</span>
            <el-date-picker
              v-model="filters.end_at"
              type="date"
              value-format="yyyy-MM-dd"
              size="small"
              placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="widget-flat">
      <div class="data-table card df-card">
        <div class="card-body"
          style="min-height: 600px;"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-background="rgba(0, 0, 0, 0.5)"
          >
          <el-table
            :data="transactions"
            style="width: 100%;">
            <el-table-column prop="id" label="ID" min-width="50"></el-table-column>
            <el-table-column prop="from_name" label="转出" min-width="150">
              <template slot-scope="scope">
                <div>转出名称：{{ scope.row.from_name }}</div>
                <div v-if="scope.row.from_user.telephone">转出电话：{{ scope.row.from_user.telephone }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="to_name" label="转入" min-width="200">
              <template slot-scope="scope">
                <div>转入名称：{{ scope.row.to_name }}</div>
                <div v-if="scope.row.to_user.telephone">转入电话：{{ scope.row.to_user.telephone }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="value" label="金额">
              <template slot-scope="scope">{{ scope.row.value / 100 }}</template>
            </el-table-column>
            <el-table-column prop="comment" label="备注" min-width="140"></el-table-column>
            <el-table-column prop="datetime" label="转账时间" min-width="160"></el-table-column>
            <el-table-column prop="" label="操作">
              <template slot-scope="scope">
                <el-link target="_blank" :href="'https://confluxscan.io/transaction/' + scope.row.hash" type="success">点击查看</el-link>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            background
            v-if="transactions.length"
            class="mt-5"
            @current-change="currentChange"
            layout="prev, pager, next"
            :total="total_records">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      transactions: [],
      current_page: 1,
      total_records: 0,
      filters: {
        trans_type: '',
        user_id: '',
        start_at: '',
        end_at: ''
      },

      filterUsers: [{name: '全部', id:''}],
      filterTransType: [
        {code: '', name: '全部'},
        {code: 'recharge', name: '充值'},
        {code: 'exchange', name: '提现'},
        {code: 'punish', name: '罚款'},
        {code: 'reward', name: '奖励'},
        {code: 'cold', name: '冷钱包'},
      ]
    }
  },

  watch: {
    filters: {
      handler(after, before) {
        this.current_page = 1;
        this.loadData();
      },
      deep: true
    },
  },

  mounted() {
    this.loadData();
    this.getUsers();
  },

  methods: {
    loadData() {
      this.loading = true;

      const _data = {
        page: this.current_page,
        trans_type: this.filters.trans_type,
        user_id: this.filters.user_id,
        start_at: this.filters.start_at,
        end_at: this.filters.end_at
      }

      this.$http.get('admin/transactions', _data).then(res => {
        this.transactions = res.data.data;
        this.total_records = res.data.total_records;
        this.current_page = res.data.current_page;
        this.loading = false;
      })
    },

    getUsers() {
      const self = this;

      this.$http.get('admin/users', {limit: 1000}).then(res => {
        if (res.data) {
          let users = res.data.data.map(e => {
            return {
              name: e.name,
              id: e.id,
            }
          });

          self.filterUsers.push(...users);
        }
      })
    },

    showDate(e) {
      console.log(e)
    },

    currentChange(e) {
      this.current_page = e;
      this.loadData();
    },
  },
}
</script>

<style lang="scss">

</style>
