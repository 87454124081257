<template>
  <div class="admin-page-wrap">
    <div class="admin-dashboard">
      <div class="wrapper">
        <div :class="['left-side-menu', sideMenu ? 'active' : '']">
          <router-link to="/admin" class="logo text-center logo-light">GDT 后台管理</router-link>
          <ul class="side-nav">
            <li class="side-nav-item">
              <router-link to="/admin" :class="['side-nav-link', routeActive('dashboard')]">
                <i class="iconfont">&#xe601;</i>首页
              </router-link>
              <router-link to="/admin/users" :class="['side-nav-link', routeActive('users')]">
              <i class="iconfont">&#xe8c8;</i>用户列表
              </router-link>
              <router-link to="/admin/transactions" :class="['side-nav-link', routeActive('transactions')]">
                <i class="iconfont">&#xe657;</i>链上交易
              </router-link>
              <router-link to="/admin/recharges" :class="['side-nav-link', routeActive('recharges')]">
                <i class="iconfont">&#xe604;</i>充值列表
              </router-link>
              <router-link to="/admin/withdraws" :class="['side-nav-link', routeActive('withdraws')]">
                <i class="iconfont">&#xe63d;</i>提现列表
              </router-link>
              <router-link :to="{name: 'orders'}" :class="['side-nav-link', routeActive('orders')]">
                <i class="iconfont">&#xe607;</i>预付单
              </router-link>
            </li>
          </ul>
        </div>
        <div :class="['content-page', sideMenu ? 'active' : '']">
          <div class="content">
            <div class="navbar-custom d-flex justify-content-between align-items-center">
              <div class="sidebar-toggle" @click="sideMenu = !sideMenu"><i class="hamburger"></i></div>

              <div v-if="profile">管理员 - {{ profile.name }}</div>
              <div class="return-frontend">
                <el-button type="primary" @click="$router.push('/')" size="small">回到前台</el-button>
              </div>
            </div>

            <div class="container-fluid">
              <div class="py-3">
                <el-breadcrumb separator="/" v-if="route.meta.tag">
                  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                  <el-breadcrumb-item>{{ route.meta.tag }}</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: this.$route,
      sideMenu: false,
      profile: null
    }
  },

  watch: {
    $route(to, from) {
      setTimeout(() => this.sideMenu = false, 200)
      this.route = to
    }
  },

  mounted() {
    this.profile = JSON.parse(localStorage.getItem('profile'));
  },

  methods: {
    routeActive(val) {
      // console.log(this.route.startsWith(''))
      if (this.route.name.startsWith(val)) {
        return 'active'
      }
    },
  },
}
</script>
<style lang="scss">
</style>
