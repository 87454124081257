import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/globalStyle.js';
import http from "./http/http";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// import '@sweetalert2/theme-bootstrap-4/bootstrap-4.min.css';
import "./http/axios";
import jQuery from 'jquery';
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// window.$ = jQuery;
window.$ = jQuery;

const options = {
  confirmButtonText: '确定',
  focusConfirm: false,
  confirmButtonColor: '#4361ee',
  cancelButtonText: '取消'
};

Vue.use(VueSweetalert2, options);

Vue.config.productionTip = false
// 对请求方式 进行全局注册
Vue.prototype.$http = http;


new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    this.$http.get('profile', null, {hmsg: true}).then(res => {
      this.$store.commit('setGdtAppConfig', res.data);
      localStorage.setItem('profile', JSON.stringify(res.data))
    })

    this.$http.get('setting', null, {hmsg: true}).then(res => {
      localStorage.setItem('setting', JSON.stringify(res.data))
    })
  }
}).$mount('#app')
