<template>
  <div class="">
    <el-dialog
      title="微信支付"
      :visible.sync="dialogVisible"
      center
      @close="qrCodePopClose"
      :destroy-on-close="true"
      width="350px">
      <div class="qrcode mb-3" ref="qrCodeUrl" style="display: flex; justify-content: center;"></div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
let _time;

export default {
  name: 'pay',
  props: {
    payCode: {
      default: 'wechat',
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },

  computed: {

  },

  mounted() {
  },

  watch:{
  },

  methods: {
    qrCodePopClose() {
      clearInterval(_time);
    },

    payStart(id, pay_code) {
      this.$http.post(`/recharges/${id}/pay`, null).then(data => {
        if (pay_code == 'alipay') {
          // this.$router.push('/recharge_list');
          window.open(data.data.url, "_blank");
          this.$emit('pay-complete', data);
        } else {
          this.dialogVisible = true;

          this.$nextTick(() => {
            var qrcode = new QRCode(this.$refs.qrCodeUrl, {
              text: data.data.url,
              width: 200,
              height: 200,
              colorDark: '#000000',
              colorLight: '#ffffff',
              correctLevel: QRCode.CorrectLevel.H
            })
          })

          _time = setInterval(() => {
            this.$http.get(`/recharges/${id}`, null, false).then(result => {
              if (result.data.status == 'paid' || result.data.status == 'transfer') {
                this.$message({message: '充值成功！',type: 'success'});
                clearInterval(_time);
                this.$emit('pay-complete', result);
              }
            })
          }, 1000)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
