import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    gdtAppConfig: { // 全局信息
      current_user: null
    }
  },

  mutations: {
    setGdtAppConfig(state, val) {
      state.gdtAppConfig.current_user = val;
    },
  },
  actions: {
  },
  modules: {
  }
})
