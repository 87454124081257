<template>
  <div class="black-table"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(0, 0, 0, 0.5)"
    >
    <div class="row mb-3" v-if="user">
      <div class="col-sm-12 mb-3 mt-2">
        <h3 class="text-white">{{ user.user.name }} 的预付单</h3>
      </div>

      <div class="col-12">
        <div class="card df-card orders-descriptions" :style="user.status != 'pending' ? 'min-height: 600px;':''">
          <div class="card-header">数据</div>
          <div class="card-body">
            <el-descriptions class="margin-top" :column="3" border>
              <el-descriptions-item>
                <template slot="label">
                  金额
                </template>
                {{ user.amount }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  类型
                </template>
                {{ user.type }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">状态</template>
                {{ user.status }}
              </el-descriptions-item>
              <el-descriptions-item span="1">
                <template slot="label">
                  操作人
                </template>
                {{ user.admin.name }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  创建时间
                </template>
                {{ user.created_at }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">更新时间</template>
                {{ user.updated_at }}
              </el-descriptions-item>
              <el-descriptions-item span="5">
                <template slot="label">
                  备注
                </template>
                {{ user.comment }}
              </el-descriptions-item>
              <el-descriptions-item span="5" v-if="user.transaction_no">
                <template slot="label">
                  链上记录
                </template>
                <el-link target="_blank" :href="'https://confluxscan.io/transaction/' + user.transaction_no" type="success">查看链上数据</el-link>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </div>
    </div>
    <div class="widget-flat" v-if="user && user.status == 'pending'">
      <div class="data-table card df-card">
        <div class="card-header">修改状态</div>
        <div class="card-body" style="min-height: 300px;">
          <div class="radio-wrap mb-4">
            <el-select v-model="status" placeholder="请选择">
              <el-option label="审核" value="approved"></el-option>
              <el-option label="取消" value="cancelled"></el-option>
            </el-select>
          </div>
          <el-button type="primary" @click="submit" size="small">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: 0,
      loading: false,
      status: 'approved',
      user: null,
    }
  },

  mounted() {
    this.id = this.$route.params.id;
    this.loadData();
  },

  methods: {
    loadData() {
      this.loading = true;

      this.$http.get(`admin/orders/${this.id}`, null).then(res => {
        this.user = res.data;
        this.loading = false;
      })
    },

    submit() {
      // this.loading = true;
      // this.$http.put(`admin/orders/${this.id}/status`, {status: this.status}).then(res => {
      //   this.loading = false;
      //   this.$message({message: res.message, type: 'success'})
      //   this.loadData();
      // })

      this.$http.post('admin/users/verify_code', null).then(res => {
        if (res.data) {
          this.$swal.fire({
            title: '验证码已发送',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            inputPlaceholder: '请输入验证码',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: (sms_code) => {
              return this.$http.put(`admin/orders/${this.id}/status`,
                {status: this.status, sms_code}, {hmsg: true}).then(res => {
                return res;
              }).catch(() => {
                this.$swal.showValidationMessage(`验证码输入错误，请重新输入`)
              })
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          }).then((res) => {
            this.$swal.fire({title: res.value.message, icon: 'success'})
          })
        }
      }).catch(() => {this.$swal.hideLoading()})

    },
  },
}
</script>
<style lang="scss">
  .orders-descriptions {
    .el-descriptions__body {
      background: #28293d;

      .el-descriptions-item__label.is-bordered-label {
        background: #383954;
      }

      .is-bordered td, .is-bordered th {
        border-color: #4f506d;
        color: #ddd;
      }
    }
  }
</style>
