<template>
  <div class="black-table">
    <div class="row" v-if="usre">
      <div class="col-sm-12 mb-3 mt-2">
        <h3 class="text-white">{{ usre.name }}</h3>
        <div class="">
          钱包地址：{{ usre.gdt_address }}
          <el-button
            type="primary"
            v-clipboard:copy="usre.gdt_address"
            size="mini"
            style="margin-left: 10px"
            icon="el-icon-document-copy" plain>复制</el-button>
        </div>
      </div>
    </div>
    <div class="row mb-3 user-top-data" v-if="usre">
      <div class="col col-top-1">
        <div class="card df-card">
          <div class="card-header">余额</div>
          <div class="card-body">
            <div class="text"><h4>{{ usre.balance }}</h4></div>
          </div>
        </div>
      </div>
      <div class="col col-top-2">
        <div class="card df-card">
          <div class="card-header">充值</div>
          <div class="card-body">
            <div class="text"><h4>{{ usre.recharge_amount }}</h4></div>
          </div>
        </div>
      </div>
      <div class="col col-top-2">
        <div class="card df-card">
          <div class="card-header">提现</div>
          <div class="card-body">
            <div class="text"><h4>{{ usre.exchange_amount }}</h4></div>
          </div>
        </div>
      </div>
      <div class="col col-top-2">
        <div class="card df-card">
          <div class="card-header">惩罚</div>
          <div class="card-body">
            <div class="text"><h4>{{ usre.punish_amount }}</h4></div>
          </div>
        </div>
      </div>
      <div class="col col-top-2">
        <div class="card df-card">
          <div class="card-header">奖励</div>
          <div class="card-body">
            <div class="text"><h4>{{ usre.reward_amount }}</h4></div>
          </div>
        </div>
      </div>
    </div>
<!--     <div class="row">
      <div class="col">
        <div class="card df-card">
          <div class="card-header"></div>
          <div class="card-body">
            ss
          </div>
        </div>
      </div>
    </div> -->
    <div class="widget-flat">
      <div class="data-table card df-card">
        <div class="card-header">链上记录</div>
        <div class="card-body"
          style="min-height: 600px;"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-background="rgba(0, 0, 0, 0.5)"
          >
          <el-table
            :data="transactions"
            style="width: 100%;">
            <el-table-column prop="id" label="ID" min-width="50"></el-table-column>
            <el-table-column prop="from_name" label="转出" min-width="150">
              <template slot-scope="scope">
                <div>转出名称：{{ scope.row.from_name }}</div>
                <div v-if="scope.row.from_user.telephone">转出电话：{{ scope.row.from_user.telephone }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="to_name" label="转入" min-width="220">
              <template slot-scope="scope">
                <div>转入名称：{{ scope.row.to_name }}</div>
                <div v-if="scope.row.to_user.telephone">转入电话：{{ scope.row.to_user.telephone }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="value" label="金额" min-width="110">
              <template slot-scope="scope">{{ scope.row.value / 100 }}</template>
            </el-table-column>
            <el-table-column prop="comment" label="备注" min-width="180"></el-table-column>
            <el-table-column prop="datetime" label="转账时间" min-width="180"></el-table-column>
            <el-table-column prop="" label="操作" min-width="150">
              <template slot-scope="scope">
                <el-link target="_blank" :href="'https://confluxscan.io/transaction/' + scope.row.hash" type="success">点击查看</el-link>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            background
            v-if="transactions.length"
            class="mt-5"
            @current-change="currentChange"
            layout="prev, pager, next"
            :total="total_records">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: 0,
      loading: false,
      usre: null,
      transactions: [],
      current_page: 1,
      total_records: 0,
      filters: {
        trans_type: '',
      },
    }
  },

  mounted() {
    this.id = this.$route.params.id;
    this.loadData();
    this.getTransactions();
  },

  methods: {
    loadData() {
      this.loading = true;

      this.$http.get(`admin/users/${this.id}`, null).then(res => {
        this.usre = res.data;
        this.loading = false;
      })
    },

    getTransactions() {
      this.loading = true;

      const _data = {
        page: this.current_page,
        trans_type: this.filters.trans_type,
        user_id: this.id,
      }

      this.$http.get('admin/transactions', _data).then(res => {
        this.transactions = res.data.data;
        this.total_records = res.data.total_records;
        this.current_page = res.data.current_page;
        this.loading = false;
      })
    },

    usresShow(id) {
      // this.$http.post('admin/transactions/sync', {user_id: id}).then(res => {
      //   this.$message({message: res.message, type: 'success'})
      // })
    },

    transactionsSync(id) {
      this.$http.post('admin/transactions/sync', {user_id: id}).then(res => {
        this.$message({message: res.message, type: 'success'})
      })
    },

    currentChange(e) {
      this.current_page = e;
      this.getTransactions();
    },
  },
}
</script>
<style lang="scss">
</style>
