<template>
  <div class="black-table">
    <div class="widget-flat card mb-3">
      <div class="card-header">筛选</div>
      <div class="card-body card-header-filter">
        <div class="filter-item">
          <div class="title">时间</div>
          <div class="filter-at-wrap">
            <el-date-picker
              v-model="filters.start_at"
              type="date"
              value-format="yyyy-MM-dd"
              size="small"
              placeholder="选择日期">
            </el-date-picker>
            <span>至</span>
            <el-date-picker
              v-model="filters.end_at"
              type="date"
              value-format="yyyy-MM-dd"
              size="small"
              placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="widget-flat">
      <div class="data-table card df-card">
        <div class="card-body"
          style="min-height: 600px;"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-background="rgba(0, 0, 0, 0.5)"
          >
          <div class="page-title-right mb-3">
            <form class="form-inline">
              <el-button type="success" class="btn btn-primary ml-1" @click="allSync" plain size="mini">同步数据</el-button>
            </form>
          </div>
          <el-table
            :data="withdraws"
            style="width: 100%;">
            <el-table-column prop="id" label="ID" min-width="50"></el-table-column>
            <el-table-column prop="from_name" label="提现用户" min-width="180">
              <template slot-scope="scope">
                <div>姓名：{{ scope.row.user.name }}</div>
                <div v-if="scope.row.user.name">电话：{{ scope.row.user.telephone }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="value" label="金额">
              <template slot-scope="scope">{{ scope.row.amount / 100 }}</template>
            </el-table-column>
            <el-table-column prop="updated_at" label="链上时间" min-width="180"></el-table-column>
            <el-table-column prop="status" label="状态"></el-table-column>
            <el-table-column prop="" label="链上记录">
              <template slot-scope="scope">
                <el-link target="_blank" :href="'https://confluxscan.io/transaction/' + scope.row.transaction_no" type="success">点击查看</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作">
              <template slot-scope="scope">
                <!-- <el-link target="_blank" :href="'https://confluxscan.io/transaction/' + scope.row.hash" type="success">点击查看</el-link> -->
                <router-link :to="{name:'withdraws.info', params: {id: scope.row.id}}">
                  <el-button type="info" plain size="small">查看</el-button>
                </router-link>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            background
            v-if="withdraws.length && withdraws.total_page > 1"
            class="mt-5"
            @current-change="currentChange"
            layout="prev, pager, next"
            :total="total_records">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      withdraws: [],
      current_page: 1,
      total_records: 0,
      filters: {
        start_at: '',
        end_at: ''
      },
    }
  },

  watch: {
    filters: {
      handler(after, before) {
        this.current_page = 1;
        this.loadData();
      },
      deep: true
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      this.loading = true;

      const _data = {
        page: this.current_page,
        start_at: this.filters.start_at,
        end_at: this.filters.end_at
      }

      this.$http.get('admin/withdraws', _data).then(res => {
        this.withdraws = res.data.data;
        this.total_records = res.data.total_records;
        this.current_page = res.data.current_page;
        this.loading = false;
      })
    },

    allSync() {
      const loading = this.$loading({text: '加载中...',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.3)'
      });

      this.$http.post('admin/withdraws/sync', null).then(res => {
        loading.close();
        this.$message({message: res.message, type: 'success'})
      })
    },

    currentChange(e) {
      this.current_page = e;
      this.loadData();
    },
  },
}
</script>
<style lang="scss">
</style>
