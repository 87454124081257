<template>
  <div class=""
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(0, 0, 0, 0.5)"
    >
    <div class="widget-flat">
      <div class="orders-table card df-card black-table">
        <div class="card-header">请填写表单创建预付单</div>
        <div class="card-body" style="min-height: 600px;">
<!--           <ul class="users-wrap">
            <li v-for="user, index in users" :key="index">
              <el-button type="success" icon="el-icon-circle-plus-outline" plain @click="addUser(user)" size="small">{{ user.name }}</el-button>
            </li>
          </ul> -->

          <div class="form-rable">
            <el-form ref="form" :rules="rules" label-width="80px" :model="form" style="max-width: 600px;" class="withdraws-ruleForm">
              <el-form-item label="用户" prop="user_id">
<!--                 <el-select v-model="form.user_id" filterable placeholder="搜索/选择人员">
                  <el-option
                    v-for="item in users"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select> -->

                <el-autocomplete
                  v-model="keyword"
                  :debounce="10"
                  value-key="name"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="金额" prop="amount">
                <el-input v-model.number="form.amount"></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="comment">
                <el-input v-model="form.comment" type="textarea" :rows="4"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit('form')" class="submit-btn">立即创建</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      users: null,
      keyword: '',
      form: {
        user_id: '',
        user_id: '',
        type: 'punish',
        amount: 1,
        comment: '',
      },
      rules: {
        user_id: [
          { required: true, message: '请填选择人员', trigger: 'change' }
        ],
        amount: [
          { type: 'number', required: true, message: '请填写金额', trigger: 'change' }
        ],
        comment: [
          { required: true, message: '请填写备注', trigger: 'change' }
        ],
      }
    }
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      this.$http.get(`admin/users`, null).then(res => {
        console.log(res)
        this.users = res.data.data;
      })
    },

    querySearchAsync(val, cb) {
      this.$http.get(`admin/users`, {active: 1, name: val}).then(res => {
        return cb(res.data.data || []);
      })
    },

    handleSelect(item) {
      this.form.user_id = item.id;
    },

    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;

          this.$http.post(`admin/orders`, this.form).then(res => {
            this.$message({message: res.message, type: 'success'})
            this.loading = false;
            setTimeout(() => {this.$router.go(-1)}, 100)
          })
        }
      });
    }
  },
}
</script>

<style lang="scss">
  .orders-table {
    .df-card .el-input__inner {

    }
  }

  .withdraws-ruleForm {
    .el-form-item__label {
      color: #ccc !important;
    }

    .el-form-item {
      margin-bottom: 28px;
    }

    .el-radio__label {
      color: #aaa !important;
      padding-left: 5px;
    }
  }

  .users-wrap {
    padding-left: 0;
    display: flex;

    li {
      list-style: none;
      margin-right: 10px;
    }
  }

  .submit-btn {
    margin-top: 30px !important;
  }
</style>
