<template>
  <div class="black-table"
  v-loading="loading"
  element-loading-text="拼命加载中"
  element-loading-background="rgba(0, 0, 0, 0.5)"
  >
    <div class="row mb-3" v-if="data">
      <div class="col-sm-12 mb-3 mt-2">
        <h3 class="text-white">{{ data.user.name }}</h3>
        <div class="">
          钱包地址：{{ data.user.gdt_address }}
          <el-button
            type="primary"
            v-clipboard:copy="data.user.gdt_address"
            size="mini"
            style="margin-left: 10px"
            icon="el-icon-document-copy" plain>复制</el-button>
        </div>
      </div>
      <div class="col">
        <div class="card df-card">
          <div class="card-header">提现金额</div>
          <div class="card-body">
            <div class="text"><h4>{{ data.amount / 100 }}</h4></div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card df-card">
          <div class="card-header">余额</div>
          <div class="card-body">
            <div class="text"><h4 :class="data.user.balance < 1000 ? 'balance-error' : ''">{{ data.user.balance }}</h4></div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card df-card">
          <div class="card-header">状态</div>
          <div class="card-body">
            <div class="text"><h4>{{ data.status }}</h4></div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card df-card">
          <div class="card-header">电话</div>
          <div class="card-body">
            <div class="text"><h4>{{ data.user.telephone }}</h4></div>
          </div>
        </div>
      </div>
    </div>

    <div class="widget-flat mb-3" v-if="data && data.status != 'paid'">
      <div class="card df-card">
        <div class="card-header">修改状态</div>
        <div class="card-body">

          <el-form ref="form" :rules="rules" :model="form" label-width="80px" class="withdraws-ruleForm">
            <el-form-item label="选择状态" prop="status">
              <el-radio-group v-model="form.status" size="small">
                <el-radio
                  :label="item.code"
                  v-for="item in filtersStatus"
                  :key="item.code">
                  {{ item.name }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
<!-- :disabled="data.user.balance < 1000 && item.code != 'pending'" -->
            <el-form-item label="填写备注" prop="comment">
              <el-input type="textarea" style="max-width: 500px;" :rows="3" placeholder="请输入内容" v-model="form.comment"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitStatus('form')">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <div class="widget-flat">
      <div class="data-table card df-card">
        <div class="card-header">操作记录</div>
        <div class="card-body"
          style="min-height: 200px;"
          element-loading-text="拼命加载中"
          element-loading-background="rgba(0, 0, 0, 0.5)"
          >
          <el-table
            :data="histories"
            style="width: 100%;">
            <el-table-column prop="id" label="ID" width="50"></el-table-column>
            <el-table-column prop="status" label="状态"></el-table-column>
            <el-table-column prop="user.name" label="操作人"></el-table-column>
            <el-table-column prop="comment" label="备注"></el-table-column>
            <el-table-column prop="created_at" label="创建时间"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: 0,
      loading: false,
      data: null,
      histories: [],
      form: {
        comment: '',
        status: '',
      },
      filtersStatus: [
        {code: 'paid', name: '线下已付'},
        {code: 'rejected', name: '拒付'},
        {code: 'pending', name: '待处理'},
      ],

      rules: {
        status: [
          { required: true, message: '必须状态选择', trigger: 'change' }
        ],

        comment: [
          { required: true, message: '备注必填', trigger: 'change' }
        ],
      }
    }
  },

  mounted() {
    this.id = this.$route.params.id;
    this.loadData();
  },

  methods: {
    submitStatus(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;

          this.$http.put(`admin/withdraws/${this.id}/status`, this.form).then(res => {
            this.$message({message: res.message, type: 'success'})
            this.loading = false;
            this.loadData();
          })
        }
      });
    },

    loadData() {
      this.loading = true;

      this.$http.get(`admin/withdraws/${this.id}`, null).then(res => {
        this.data = res.data;
        this.histories = res.data.histories;

        if (res.data.status == 'transfer') {
          this.filtersStatus = [{code: 'paid', name: '线下已付'}, {code: 'rejected', name: '拒付'}, {code: 'pending', name: '待处理'}];
        }

        if (res.data.status == 'rejected') {
          this.filtersStatus = [{code: 'paid', name: '线下已付'}];
          this.form.status = 'paid';
        }

        if (res.data.status == 'padding') {
          this.filtersStatus = [{code: 'paid', name: '线下已付'}, {code: 'rejected', name: '拒付'}];
        }

        this.loading = false;
      })
    },
  },
}
</script>

<style lang="scss">

  .balance-error {
    color:red;
  }

  .withdraws-ruleForm {
    .el-form-item__label {
      color: #ccc !important;
    }

    .el-form-item {
      margin-bottom: 28px;
    }

    .el-radio__label {
      color: #aaa !important;
      padding-left: 5px;
    }
  }
</style>
