<template>
  <div class="page-recharge">
    <div class="row justify-content-center align-items-center">
      <div class="col-md-7 text-shadow">
        <div class="card df-card shadow-lg shadow-lg py-4" style="background-color: rgba(40, 42, 62, .85);">
          <div class="card-body">
            <h3 class="card-title text-center mt-2 mb-5 text-white">GDT 充值</h3>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px">
              <el-form-item label="充值金额" prop="amount">
                <el-input
                  v-model="form.amount"
                  @input="form.amount = form.amount.toString().replace(/^(0+)|[^\d]+/g,'')"
                  autocomplete="off" placeholder="请填写充值金额">
                </el-input>
              </el-form-item>
              <el-form-item label="支付方式">
                <div class="paycode-wrap">
                  <el-radio v-model="form.payCode" label="wechat" border>微信支付</el-radio>
                  <el-radio v-model="form.payCode" label="alipay" border>支付宝</el-radio>
                </div>
              </el-form-item>
              <el-form-item class="mt-5">
                <el-button type="primary" @click="submitForm('form')">提交</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <Pay ref="pay" @pay-complete="payCompleteBack"  />
  </div>
</template>
<script>

import Pay from '@/components/pay'

export default {
  components: {
    Pay,
  },

  data() {
    return {
      form: {
        amount: '',
        payCode: 'wechat'
      },

      dialogVisible: false,

      rules: {
        amount: [
          { required: true, message: '请输入充值金额', trigger: 'change' }
        ],
      }
    }
  },

  mounted() {
  },

  methods: {
    payCompleteBack(e) {
      if (e.data.pay_code == 'wechat') {
        setTimeout(() => {
          this.$router.push('/recharge_list');
        }, 1000)
      } else {
        this.$alert('已完成支付，查看记录', '支付提示', {confirmButtonText: '确定', callback: action => {
            this.$router.push('/recharge_list');
          }
        });
      }
    },

    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$http.post('/recharges', this.form).then(res => {
            if (!res.data) return this.$message({message: res.message, type: 'error'});
            this.$refs.pay.payStart(res.data, this.form.payCode);
          })
        }
      });
    },
  },
}
</script>


<style lang="scss">
  .page-recharge {
    @media (min-width: 768px) {
      margin-top: 6rem;
    }

    @media (max-width: 768px) {
      .el-form-item__label {
        width: 100% !important;
        text-align: left;
      }

      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }

  .paycode-wrap {
    @media (max-width: 768px) {
      > label {
        margin: 0;
        padding: 12px 10px 0 10px !important;
      }
    }

    .el-radio {
      color: #aaa;
    }

    .el-radio__inner {
      border-color: #8b96b7;
    }
  }
</style>
