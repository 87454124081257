<template>
  <div class="page-recharge-list pb-3">
    <div class="data-table card df-card">
      <div class="card-body">
        <div class="info">
          <div class="img"><img src="@/assets/images/pay-s.png" class="img-fluid"></div>
          <div class="text">充值成功</div>
          <!-- <router-link to="/recharge_list" class="btn btn-link btn-right">查看充值记录</router-link> -->
           <router-link to="/recharge_list"  class="btn btn-success btn-right mt-4">查看充值记录</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },

  computed: {

  },

  mounted() {
  },

  methods: {

  },
}
</script>


<style scoped lang="scss">
.info {
  text-align: center;
  padding: 70px 0;
  .img {
    display: flex;
    justify-content: center;
    img {
      max-width: 300px;
    }
  }

  .text {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
  }
}
</style>
