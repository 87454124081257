<template>
  <div class=""
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(0, 0, 0, 0.5)"
    >
    <div class="widget-flat">
      <div class="orders-table card df-card black-table">
        <div class="card-header">点击添加人员</div>
        <div class="card-body">
          <ul class="users-wrap">
            <li class="">
              <el-button type="primary" icon="el-icon-circle-plus-outline" plain @click="addUser('all')" size="small">所有人</el-button>
            </li>
            <li v-for="user, index in users" :key="index">
              <el-button type="success" icon="el-icon-circle-plus-outline" plain @click="addUser(user)" size="small">{{ user.name }}</el-button>
            </li>
          </ul>

          <div class="form-rable">
            <el-table
              :data="data"
              height="450"
              style="width: 100%;">
              <el-table-column type="index" label="" width="40"></el-table-column>
              <el-table-column prop="name" label="用户" width="100"></el-table-column>
              <el-table-column prop="amount" label="金额（必填）" width="200">
                <template slot-scope="scope">
                  <el-input v-model.number="scope.row.amount" size="small" placeholder="请输入内容"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="comment" label="备注（必填）" width="">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.comment" size="small" placeholder="请输入内容"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="" label="操作" width="100" align="center">
                <template slot-scope="scope">
                  <el-button type="danger" icon="el-icon-delete" size="small" @click="removeTable(scope.$index)"></el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="summary-wrap" v-if="data.length">
              <div class="left">批量设置</div>
              <div class="batch-amount">
                <el-input v-model.number="batchAmount" size="small" placeholder="批量设置金额"></el-input>
              </div>
              <div class="batch-comment">
                <el-input v-model="batchComment" size="small" placeholder="批量输入内容"></el-input>
              </div>
            </div>

            <div v-if="data.length" class="summary-bottom">
              <el-button type="primary" v-if="data.length" @click="submit" style="width: 100px;">提交</el-button>
              <div class="summary-right">
                <span>合计：</span>
                <div class="right">{{ summary }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      users: null,
      batchAmount: '',
      batchComment: '',
      data: []
    }
  },

  mounted() {
    this.loadData();
  },

  watch:{
    batchAmount (e) {
      this.data.forEach(x => x.amount = e);
    },

    batchComment (e) {
      this.data.forEach(x => x.comment = e);
    },
  },

  computed: {
    summary() {
      return this.data.map(e => e.amount * 1).reduce((total, num) => total + num);
        // .toFixed(2)
    }
  },

  methods: {
    loadData() {
      this.$http.get(`admin/users`, {active: 1, limit: 1000}).then(res => {
        this.users = res.data.data;
      })
    },

    addUser(item) {
      if (item == 'all') {
        this.users.forEach(e => {
          this.data.push({name: e.name, amount: 0, comment: "", type: 'reward', userId: e.id});
        });
        return;
      }

      this.data.push({name: item.name, amount: 0, comment: "", type: 'reward', userId: item.id});
    },

    removeTable(index) {
      this.data.splice(index, 1);
    },

    submit() {
      this.loading = true;
      this.$http.post(`admin/orders/batch_create`, {orders: this.data}).then(res => {
        this.$message({message: res.message, type: 'success'})
        this.data = [];
        this.batchAmount = '';
        this.batchComment = '';
        this.loading = false;
        setTimeout(() => {this.$router.go(-1)}, 200)
      }).catch(res => {
        console.log(111)
        // Message.error('请求失败，请稍后再试');
      });
    }
  },
}
</script>

<style lang="scss">
  .orders-table {
    .df-card .el-input__inner {

    }
  }

  .users-wrap {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      list-style: none;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .summary-bottom {
    display: flex;
    align-items: center;
    // justify-content: space-between;

    .summary-right {
      display: flex;
      align-items: center;
      margin-left: 30px;

      .right {
        font-size: 26px;
        font-weight: bold;
        color: #fd5631;
        line-height: 1;
      }
    }
  }

  .summary-wrap {
    display: flex;
    background: #3b3f54;
    align-items: center;
    margin-bottom: 20px;

    > div {
      padding: 7px 10px;
      font-size: 14px;
      font-weight: bold;

      &.left {
        width: 140px;
        text-align: center;
      }

      &.batch-amount {
        input {
          width: 180px;
        }
      }

      &.batch-comment {
        flex: 1;
      }

      // &.right {
      //   width: 114px;
      //   text-align: center;
      //   color: #fff;
      // }
    }
  }
</style>
