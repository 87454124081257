<template>
  <div class="black-table">
    <div class="widget-flat card mb-3">
      <div class="card-header">筛选</div>
      <div class="card-body card-header-filter">
        <div class="filter-item">
          <div class="title">用户</div>
          <el-select v-model="filters.user_id" placeholder="选择/搜索" size="small" filterable>
            <el-option
              v-for="item in filterUsers"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="filter-item users-sort-btns">
          <div class="title">排序</div>
          <el-button-group>
            <el-button
              v-for="item, index in sorts"
              :key="index"
              size="small"
              :type="filters.sort == item.sort ? 'primary' : ''"
              @click="onSort(item.sort, item.order, index)">
                {{ item.text }}
                <i v-if="item.sort != '' && filters.sort == item.sort" :class="filters.order == 'asc' ? 'el-icon-top' : 'el-icon-bottom'"></i>
            </el-button>
          </el-button-group>
        </div>
      </div>
    </div>

    <div class="widget-flat">
      <div class="data-table card df-card">
        <div class="card-body"
          style="min-height: 600px;"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-background="rgba(0, 0, 0, 0.5)"
          >
          <div class="page-title-right mb-3">
            <form class="form-inline">
              <el-button type="success" class="ml-1" @click="allSync" plain size="small">同步全部</el-button>
            </form>
          </div>
          <el-table
            :data="usres"
            class="usres-table"
            style="width: 100%;">
            <el-table-column prop="id" label="ID" width="50"></el-table-column>
            <el-table-column prop="name" label="名称" width="100"></el-table-column>
            <el-table-column prop="balance" :class-name="filters.sort == 'balance' ? 'active' : ''" label="余额"></el-table-column>
            <el-table-column prop="punish" :class-name="filters.sort == 'punish' ? 'active' : ''" label="罚款"></el-table-column>
            <el-table-column prop="recharge" :class-name="filters.sort == 'recharge' ? 'active' : ''" label="充值"></el-table-column>
            <el-table-column prop="reward" :class-name="filters.sort == 'reward' ? 'active' : ''" label="奖励"></el-table-column>
            <el-table-column prop="withdraw" :class-name="filters.sort == 'withdraw' ? 'active' : ''" label="提现"></el-table-column>
            <el-table-column prop="telephone" label="电话" min-width="120"></el-table-column>
            <el-table-column prop="telephone" label="状态" width="80">
              <template slot-scope="scope">
                <el-switch :disabled="scope.row.id == currentId" @change="(e) => {usersActiveChange(e, scope.row.id, scope.$index)}" v-model="scope.row.active" active-color="#67C23A" :active-value="1" :inactive-value="0"></el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" width="150">
              <template slot-scope="scope">
                <router-link :to="{name:'users.info', params: {id: scope.row.id}}">
                  <el-button type="info" plain size="small">查看</el-button>
                </router-link>
                <el-button type="success" plain style="margin-left: 10px;" size="small" @click="transactionsSync(scope.row.id)">同步</el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            background
            v-if="usres.length"
            class="mt-5"
            @current-change="currentChange"
            layout="prev, pager, next"
            :total="total_records">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      usres: [],
      current_page: 1,
      filterUsers: [{name: '全部', id:''}],
      total_records: 0,
      currentId: null,
      filters: {
        name: '',
        sort: '',
        order: '',
      },
      sorts: [
        {sort: '', order: '', text: '全部'},
        {sort: 'balance', order: 'desc', text: '余额'},
        {sort: 'punish', order: 'desc', text: '罚款'},
        {sort: 'recharge', order: 'desc', text: '充值'},
        {sort: 'reward', order: 'desc', text: '奖励'},
        {sort: 'withdraw', order: 'desc', text: '提现'},
      ]
    }
  },

  created() {
    this.currentId = JSON.parse(localStorage.getItem("profile")).id;
  },

  mounted() {
    this.loadData();
    this.getUsers();
  },

  watch: {
    filters: {
      handler(after, before) {
        this.current_page = 1;
        this.loadData();
      },
      deep: true
    },
  },

  methods: {
    loadData() {
      this.loading = true;

      const _data = {
        page: this.current_page,
        user_id: this.filters.user_id,
        order: this.filters.order,
        sort: this.filters.sort,
      }

      this.$http.get('admin/users', _data).then(res => {
        this.usres = res.data.data || [];
        this.total_records = res.data.total_records;
        this.current_page = res.data.current_page;
        this.loading = false;
      })
    },

    usersActiveChange(e, id, index) {
      this.$http.put(`admin/users/${id}/change_status`, {status: e}).then(res => {
        if (res.data) {
          this.$swal.fire({icon: 'success', title: '状态修改成功',showConfirmButton: false, timer: 1000});
        }
      }).catch(e => {
        this.usres[index].active = 1;
      })
    },

    onSort(sort, order, index) {
      let direction = "desc";
      if (this.filters.sort == sort) {
        direction = this.filters.order == "asc" ? "desc" : "asc";
      }

      this.filters.order = direction;
      this.filters.sort = sort;
    },

    getUsers() {
      const self = this;

      this.$http.get('admin/users', {limit: 1000}).then(res => {
        if (res.data) {
          let users = res.data.data.map(e => {
            return {
              name: e.name,
              id: e.id,
            }
          });

          self.filterUsers.push(...users);
        }
      })
    },

    transactionsSync(id) {
      const loading = this.$loading({text: '加载中...',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.3)'
      });

      this.$http.post('admin/transactions/sync', {user_id: id}).then(res => {
        this.$message({message: res.message, type: 'success'})
        loading.close();
      })
    },

    allSync() {
      const loading = this.$loading({text: '加载中...',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.3)'
      });

      this.$http.post('admin/transactions/sync', {user_id: ''}).then(res => {
        loading.close();
        this.$message({message: res.message, type: 'success'})
      })
    },

    currentChange(e) {
      this.current_page = e;
      this.loadData();
    },
  },
}
</script>
<style lang="scss">
  .users-sort-btns .el-button:not(.el-button--primary) {
    background: #383a56;
    border-color: #4e5d8c;
    color: #ccc;
  }

  .usres-table {
    thead {
      th.active {
        color: #fd5631;
      }
    }
  }
</style>
