<template>
  <div class="page-recharge-list pb-3">
    <div class="data-table card df-card">
      <div class="card-body"
        style="min-height: 600px;"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        >
        <el-table
          :data="tableData"
          style="width: 100%;">
          <el-table-column prop="id" label="ID" min-width="50"></el-table-column>
          <el-table-column prop="created_at" label="日期" min-width="210"></el-table-column>
          <el-table-column prop="amount" label="金额" min-width="110"></el-table-column>
          <el-table-column prop="pay_code" label="支付方式" min-width="120">
            <template slot-scope="scope">
              <div v-if="scope.row.pay_code == 'alipay'">支付宝</div>
              <div v-else>微信支付</div>
            </template>
          </el-table-column>
          <el-table-column prop="transaction_no" label="链上记录" min-width="120">
            <template slot-scope="state" v-if="state.row.transaction_no">
              <el-link target="_blank" :href="'https://confluxscan.io/transaction/' + state.row.transaction_no" type="success">点击查看</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" min-width="180">
            <template slot-scope="scope">
              <template v-if="scope.row.status == 'pending'">
                <el-button  size="mini" type="primary" @click="continuePay(scope.row.id, scope.row.pay_code)">继续支付</el-button>
                <el-button  size="mini" type="primary" plain @click="cancelPay(scope.row.id, scope.$index)">取消</el-button>
              </template>
              <template v-else>
                <span>{{ scope.row.status }}</span>
              </template>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          background
          v-if="tableData.length"
          class="mt-5"
          @current-change="currentChange"
          layout="prev, pager, next"
          :total="total_records">
        </el-pagination>
      </div>
    </div>
    <Pay ref="pay" @pay-complete="payCompleteBack" />
  </div>
</template>
<script>
// import PayStart from '@/common/pay'
import Pay from '@/components/pay'

export default {
  components: {
    Pay,
  },

  data() {
    return {
      loading: false,
      tableData: [],
      current_page: 1,
      total_records: 0,
    }
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      this.loading = true;

      const _data = {
        page: this.current_page
      }

      this.$http.get('recharges', _data).then(res => {
        this.tableData = res.data.data;
        this.total_records = res.data.total_records;
        this.current_page = res.data.current_page;
        this.loading = false;
      })
    },

    cancelPay(id, index) {
      this.$http.post(`recharges/${id}/cancel`, null).then(res => {
        this.$message({message: '已取消！',type: 'success'});
        this.tableData[index].status = 'cancelled';
      })
    },

    continuePay(id, pay_code) {
      this.$refs.pay.payStart(id, pay_code);
    },

    payCompleteBack(e) {
      if (e.data.pay_code == 'wechat') {
        location.reload();
      } else {
        this.$alert('已完成支付，查看记录', '支付提示', {confirmButtonText: '确定', callback: action => {
            location.reload();
          }
        });
      }
    },

    currentChange(e) {
      this.current_page = e;
      this.loadData();
    },
  },
}
</script>
