<template>
  <div class=""
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(0, 0, 0, 0.5)"
    >
    <div class="row mb-4 header-data" v-if="dashboard">
      <div class="col-lg-3 col-md-6 col-6">
        <div class="widget-flat card">
          <div class="card-header"><h5 class="fw-light mb-0">充值</h5></div>
          <div class="card-body color-balance">
            <h3 class="title">{{ dashboard.recharge_amount }}</h3>
            <div class="info"><i class="iconfont">&#xe614;</i></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-6">
        <div class="widget-flat card">
          <div class="card-header"><h5 class="fw-light mb-0">提现</h5></div>
          <div class="card-body color-exchange">
            <h3 class="title">{{ dashboard.exchange_amount }}</h3>
            <div class="info"><i class="iconfont">&#xe63d;</i></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-6">
        <div class="widget-flat card">
          <div class="card-header"><h5 class="fw-light mb-0">奖励</h5></div>
          <div class="card-body color-reward">
            <h3 class="title">{{ dashboard.reward_amount }}</h3>
            <div class="info"><i class="iconfont">&#xe6d1;</i></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-6">
        <div class="widget-flat card">
          <div class="card-header"><h5 class="fw-light mb-0">惩罚</h5></div>
          <div class="card-body color-punish">
            <h3 class="title">{{ dashboard.punish_amount }}</h3>
            <div class="info"><i class="iconfont">&#xe60b;</i></div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="dashboard">
      <div class="row mb-4">
        <div class="col-12">
          <div class="card">
            <div class="card-header"><h5 class="fw-light mb-0">惩罚统计</h5></div>
            <div class="card-body" style="height: 260px;">
              <line-chart style="height: 100%"
                ref="bigChart"
                chart-id="big-line-chart"
                :chart-data="punishTrendsLineChart.chartData"
                :gradient-colors="punishTrendsLineChart.gradientColors"
                :gradient-stops="punishTrendsLineChart.gradientStops"
                :extra-options="punishTrendsLineChart.extraOptions">
              </line-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
<!--         <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="fw-light mb-0">奖励统计</h5>
                </div>
                <div class="col-sm-6">

                </div>
              </div>
            </div>
            <div class="card-body" style="height: 260px;">
              <line-chart style="height: 100%"
                ref="bigChart"
                chart-id="big-line-chart"
                :chart-data="rewardTrendsLineChart.chartData"
                :gradient-colors="rewardTrendsLineChart.gradientColors"
                :gradient-stops="rewardTrendsLineChart.gradientStops"
                :extra-options="rewardTrendsLineChart.extraOptions">
              </line-chart>
            </div>
          </div>
        </div> -->
        <div class="col-lg-6 mb-4 mb-lg-0">
          <div class="card">
            <div class="card-header"><h5 class="fw-light mb-0">提现统计</h5></div>
            <div class="card-body" style="height: 260px;">
              <line-chart style="height: 100%"
                ref="bigChart"
                chart-id="big-line-chart"
                :chart-data="exchangeTrendsLineChart.chartData"
                :gradient-colors="exchangeTrendsLineChart.gradientColors"
                :gradient-stops="exchangeTrendsLineChart.gradientStops"
                :extra-options="exchangeTrendsLineChart.extraOptions">
              </line-chart>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-header"><h5 class="fw-light mb-0">充值统计</h5></div>
            <div class="card-body" style="height: 260px;">
              <line-chart style="height: 100%"
                ref="bigChart"
                chart-id="big-line-chart"
                :chart-data="rechargeTrendsLineChart.chartData"
                :gradient-colors="rechargeTrendsLineChart.gradientColors"
                :gradient-stops="rechargeTrendsLineChart.gradientStops"
                :extra-options="rechargeTrendsLineChart.extraOptions">
              </line-chart>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>

import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';
import * as chartConfigs from '@/components/Charts/config';
import config from '@/config';

export default {
  components: {
    LineChart,
    BarChart,
  },

  data() {
    return {
      loading: false,
      orgOptions: {},
      dashboard: null,
      punishTrendsLineChart: {
        chartData: {
          datasets: [{
            label: "惩罚",
            fill: true,
            borderColor: '#dc3545',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#dc3545',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#dc3545',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [],
          }],
          labels: [],
        },
        gradientColors: ['rgba(220, 53, 69, 0.35)', 'rgba(220, 53, 69, 0.0)', 'rgba(220, 53, 69, 0)'],
        extraOptions: chartConfigs.punishTrendsChartOptions,
        // gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },

      rewardTrendsLineChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: [],
          datasets: [{
            label: "Data",
            fill: true,
            borderColor: '#42b883',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#42b883',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#42b883',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [],
          }]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0],
      },

      exchangeTrendsLineChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: [],
          datasets: [{
            label: "提现",
            fill: true,
            borderColor: '#1d8cf8',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#1d8cf8',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#1d8cf8',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [],
          }]
        },
        gradientColors: ['rgba(29,140,248,0.15)', 'rgba(66,92,134,0.0)', 'rgba(66,92,134,0)'],
        gradientStops: [1, 0.4, 0],
      },

      rechargeTrendsLineChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: [],
          datasets: [{
            label: "充值",
            fill: true,
            borderColor: '#42b883',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#42b883',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#42b883',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [],
          }]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
      },
    }
  },

  computed: {

  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      this.loading = true;
      this.$http.get('/admin/dashboard', null).then(res => {
        this.dashboard = res.data;
        this.punishTrendsLineChart.chartData.datasets[0].data = res.data.punish_trends.map(e => e.amount);
        this.punishTrendsLineChart.chartData.labels = res.data.punish_trends.map(e => e.date_format);

        this.rewardTrendsLineChart.chartData.datasets[0].data = res.data.reward_trends.map(e => e.amount);
        this.rewardTrendsLineChart.chartData.labels = res.data.reward_trends.map(e => e.date_format);

        this.rechargeTrendsLineChart.chartData.datasets[0].data = res.data.recharge_trends.map(e => e.amount);
        this.rechargeTrendsLineChart.chartData.labels = res.data.recharge_trends.map(e => e.date_format);

        this.exchangeTrendsLineChart.chartData.datasets[0].data = res.data.exchange_trends.map(e => e.amount);
        this.exchangeTrendsLineChart.chartData.labels = res.data.exchange_trends.map(e => e.date_format);
        // punish_trends // 罚款
        // reward_trends // 奖励
        // recharge_trends // 充值
        // exchange_trends // 提现
        this.loading = false;
      })
    },
  },
}
</script>
<style lang="scss">
.chart {
  height: 400px;
}
</style>
