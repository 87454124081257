<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
  },

  data() {
    return {
      route: this.$route
    }
  },

  watch: {
    // $route(to, from) {
    //   this.route = to
    // }
  },

  computed: {
  },

  mounted() {
  },

  methods: {

  }
}
</script>

<style scoped lang="scss">
</style>