<template>
  <div class="header-wrap">
    <header class="header-sticky text-white">
      <div class="container-fluid d-flex justify-content-between align-items-center py-3">
        <h1 class="left-logo mb-0"><router-link to="/">GDT 中心</router-link></h1>
        <div class="mb-right">
          <div class="sidebar-toggle" @click="sideMenu = !sideMenu">
            <i class="hamburger"></i>
          </div>
        </div>
        <div :class="['right-nav', sideMenu ? 'active' : '']" v-if="user">
          <ul class="nav" v-if="!sideMenu">
            <li :class="['nav-item', routeActive('home')]">
              <router-link class="nav-link" to="/">首页</router-link>
            </li>
            <li :class="['nav-item', routeActive('recharge')]">
              <router-link class="nav-link" :to="{name: 'recharge'}">充值</router-link>
            </li>
            <li :class="['nav-item', routeActive('frontend_transactions')]">
              <router-link class="nav-link" :to="{name: 'frontend_transactions'}">链上数据</router-link>
            </li>
            <li :class="['nav-item', routeActive('frontend_withdraws')]">
              <router-link class="nav-link" :to="{name: 'frontend_withdraws'}">提现申请</router-link>
            </li>
            <li :class="['nav-item', routeActive('recharge_list')]">
              <router-link class="nav-link" to="/recharge_list">充值记录</router-link>
            </li>
            <li class="nav-item">
              <div class="nav-item-box nav-link">
                <div class="user-name">
                  <a id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ user.name }}<span> ({{ user.telephone }})</span>
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><router-link to="/change_password" class="dropdown-item">修改密码</router-link></li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="divider"><span></span></li>
            <li class="nav-item nav-item-right">
              <div class="btn exchange-btn" @click="onExchange">提现申请</div>
            </li>
            <li class="nav-item nav-item-right" v-if="user.is_admin">
              <router-link :to="{name: 'dashboard'}" class="btn nav-link-admin">前往后台</router-link>
            </li>
            <li class="nav-item nav-item-right"><a class="nav-link logout" href="#" @click="login"><i class="iconfont">&#xe6bc;</i> 退出</a></li>
          </ul>
        </div>
      </div>
    </header>

    <el-drawer :visible.sync="sideMenu" :with-header="false" size="80%" custom-class="mb-wrap" v-if="user">
      <ul class="nav mb-nav">
        <li class="nav-item">
          <div class="nav-item-box nav-link">
            <div class="user-name">
              <a id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {{ user.name }}<span> ({{ user.telephone }})</span>
              </a>
            </div>
          </div>
        </li>
        <li :class="['nav-item', routeActive('frontend_transactions')]">
          <router-link class="nav-link" :to="{name: 'frontend_transactions'}">链上数据</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{name: 'frontend_withdraws'}">提现记录</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/recharge_list">充值记录</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{name: 'change_password'}">修改密码</router-link>
        </li>
        <li class="nav-item"><a class="nav-link" href="#" @click="login">退出</a></li>
        <li class="nav-item" v-if="user.is_admin">
          <router-link :to="{name: 'dashboard'}" class="nav-link nav-link-admin">前往后台</router-link>
        </li>
        <div class="side-menu-hine" @click="sideMenu = false"><i class="el-icon-right"></i></div>
      </ul>
    </el-drawer>
    <el-dialog
      title="提现(请使用牛牛钱包扫码转账)"
      :visible.sync="dialogVisible"
      center
      @close="qrCodePopClose"
      :destroy-on-close="true"
      width="350px">
      <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
        <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
        </symbol>
        <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
        </symbol>
        <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
        </symbol>
      </svg>
      <div class="qrcode mb-4" ref="qrCodeUrl" style="display: flex; justify-content: center;"></div>
      <!-- <div class="sub-title" v-if="setting">或者复制以下代码浏览器插件转账：<br>{{ setting.exchange_address }}</div> -->
      <div class="alert alert-primary d-flex align-items-center mb-2" role="alert" v-if="setting">
        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill"/></svg>
        <div>
          或者复制下面代码浏览器插件转账：
          <div class="mt-1">{{ setting.exchange_address }}</div>
        </div>
      </div>
      <el-alert
        v-if="user"
        :title="'注：当前可提现金额为：' + (user.balance - 1000) + ' GDT'"
        :closable="false"
        effect="dark"
        show-icon
        type="warning">
      </el-alert>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
// import $ from 'jquery';

export default {
  name: 'appheader',
  props: {
    msg: String
  },
  data() {
    return {
      route: this.$route,
      dialogVisible: false,
      setting: null,
      sideMenu: false
    }
  },

  computed: {
    user() {
      return this.$store.state.gdtAppConfig.current_user
      // get() {
      // },
      // set() {
      // }
    },
  },

  mounted() {
  },

  watch: {
    $route(to, from) {
      setTimeout(() => this.sideMenu = false, 200);
      this.route = to;
    }
  },

  methods: {
    routeActive(val) {
      if (this.route.name == val) {
        return 'active'
      }
    },

    qrCodePopClose() {
      // clearInterval(_time);
    },

    onExchange() {
      this.setting = JSON.parse(localStorage.getItem('setting'));
      if (!this.setting.exchange_address) return;

      this.dialogVisible = true;

      this.$nextTick(() => {
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: this.setting.exchange_address,
          width: 280,
          height: 280,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      })
    },

    login() {
      this.$http.get('auth/logout', null).then(result => {
        this.$store.commit('setGdtAppConfig', null);
        localStorage.setItem('Authorization', null)
        localStorage.setItem('profile', null)
        this.$router.push('/login');
      })
    }
  }
}

$(document).ready(function() {
  if ($(window).width() > 992) {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 20) {
        $('.header-sticky').addClass('header-fixed');
        // if ($('.menu-old').length > 0) return;
        // $('.header-sticky').before('<div class="menu-old" style="height:' + $('.header-sticky').height() + 'px; width:100%;"></div>');
      } else {
        $('.header-sticky').removeClass('header-fixed');
        // $('.menu-old').remove();
      }
    });
  }
});

</script>
